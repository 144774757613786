
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "profile",
  components: {

  },
  setup() {
    const store = useStore();
    const route = useRouter();

    const checkUserAuth = computed(() => {
      return (
        Object.keys(store.getters.currentUser).length !== 0 &&
        store.getters.isUserAuthenticated
      );
    });

    const currentUser = computed(() => {
      if (!checkUserAuth.value) return;
      return store.getters.currentUser;
    });

    const currentUserProfile = computed(() => {
      return store.getters.currentUserProfile;
    });

    const userInitial = computed(() => {
      return `${currentUser.value.name.charAt(
        0
      )}${currentUser.value.surname.charAt(0)}`;
    })
    return {
      currentUser,
      userInitial,
      currentUserProfile
    };
  },
});
